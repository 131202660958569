<template>


  <a-spin class="loadingClass" v-if="dataValue.loading === true" tip="正在加载数据 ..."></a-spin>
  <div v-if="dataValue.loading === false" class="divTab">

    <table class="ahuiTable">
      <tr>
        <th>编号</th>
        <th>BOM单号</th>
        <th>BOM类型</th>
        <th>型号</th>
        <th>厂商</th>
        <th>封装</th>
        <th>批号</th>
        <th>数量</th>
        <th>价格</th>
        <th>货币</th>
        <th>时间</th>
      </tr>
      <tr v-for="aRow in dataValue.output.list" :style="allEvent.rowColor.selectRowColor(aRow['indexId'])"
          @click="allEvent.rowColor.selectRowEvent(aRow['indexId'])" >
        <td class="min">{{ aRow['indexId'] }}</td>
        <td class="min">{{ aRow['billCode'] }}</td>
        <td class="min">{{ aRow['billTypeName'] }}</td>
        <td class="hh">{{ aRow['modelName'] }}</td>
        <td class="hh">{{ aRow['manufacturerName'] }}</td>
        <td class="hh">{{ aRow['batchName'] }}</td>
        <td class="hh">{{ aRow['envelopName'] }}</td>
        <td class="min">{{ aRow['number'] }}</td>
        <td class="price">{{ parseFloat( aRow['price'] ).toFixed(4) }}</td>
        <td class="min">{{ aRow['moneyTypeName'] }}</td>
        <td class="min">{{ aRow['createTime'] }}</td>
      </tr>

    </table>

  </div>


</template>

<script>

import {reactive} from "vue";
import proxy from "@/utils/axios";
import {message} from "ant-design-vue";

export default {
  name : "up_company_detail_model2",
  props : [ 'list_company', 'detail_company' ],
  setup(props) {

    let dataValue = reactive({
      loading : false,
      selectRow : -1,

      input :{
        listCompanyName : props.list_company,
        detailCompanyName : props.detail_company
      },
      output :{
        rowCount : 0,
        list : []
      }
    })

    let allEvent = {

      rowColor : {
        selectRowColor : (rowId) => {
          return dataValue.selectRow === rowId ? "background-color:#d8ffd8;" : "";
        },
        selectRowEvent : (rowId) => {
          dataValue.selectRow = rowId;
        }
      },



      getModel : {
        http : () => {
          dataValue.loading = true;
          proxy.newApi('/uplater/bom/get.company.detail.model', dataValue.input).then((res) => {
            if (res.code !== 200) {
              message.error(res.message);
            } else {
              dataValue.output.rowCount = res.data["rowCount"];
              dataValue.output.list = res.data["list"];
              dataValue.loading = false;
            }
          })
        }
      },

      init : ()=>{
        allEvent.getModel.http();
      }

    }

    allEvent.init();

    return {
      dataValue, allEvent
    }

  }
}
</script>

<style scoped>
.divSearch { left: 0; width: 100%; padding: 10px; }
.divSearch .model { width: 200px; margin-left: 10px; }
.divSearch .company { width: 250px; }
.divSearch .buttSearch { margin-left: 10px; }
.divTab { width: 100%; padding: 0; }
.loadingClass { width: 100%; text-align: center; padding-top: 100px; }

.set{ margin-right:5px; }
</style>