<template>


  <a-spin class="loadingClass" v-if="dataValue.companyDetailBillList.loading === true" tip="正在加载数据 ..."></a-spin>
  <div v-if="dataValue.companyDetailBillList.loading === false" class="divTab">
    <table class="ahuiTable">
      <tr>
        <th>编号</th>
        <th>BOM单号</th>
        <th>BOM类型</th>
        <th>BOM公司名</th>
        <th>关联型号种类数</th>
        <th>上传时间</th>
        <th>型号</th>
      </tr>

      <tr v-for="aRow in dataValue.companyDetailBillList.output.list" :style="allEvent.rowColor.selectRowColor(aRow['indexId'])"
          @click="allEvent.rowColor.selectRowEvent(aRow['indexId'])" >
        <td class="min">{{ aRow['indexId'] }}</td>
        <td class="min">{{ aRow['billCode'] }}</td>
        <td class="hh">{{ aRow['billTypeName'] }}</td>
        <td class="hh">{{ aRow['companyName'] }}</td>
        <td class="min">{{ aRow['modelSize'] }}</td>
        <td class="min">{{ aRow['createTime'] }}</td>
        <td class="min"><a @click="allEvent.showModel2.open(aRow)">查看</a></td>
      </tr>

    </table>

  </div>

  <a-drawer
      title="查看型号信息"
      width="950"
      :visible="dataValue.showModel2.visible"
      :maskClosable="true"
      :destroyOnClose="true"
      placemant="right"
      @close="allEvent.showModel2.goClose"
  >
    <detailModel :list_company="dataValue.showModel2.listCompany" :detail_company="dataValue.showModel2.detailCompany" />
  </a-drawer>

</template>

<script>

import {reactive} from "vue";
import proxy from "@/utils/axios";
import {message} from "ant-design-vue";
import detailModel from "@/view/uplater/up_company_detail_model";

export default {
  name : "up_company_detail_bill",
  props : [ 'row_obj' ],
  components : { detailModel },
  setup(props) {

    let dataValue = reactive({
      rowObj : props.row_obj,
      companyDetailBillList : {

        loading : false,
        selectRow : 0,

        input : {
          "companyName" : ""
        },
        output : {
          "rowCount" : 0,
          "list" : []
        }
      },
      showModel2 : {
        listCompany : "",
        detailCompany : "",
        visible : false
      }

    })

    let allEvent = {

      rowColor : {
        selectRowColor : (rowId) => {
          return dataValue.companyDetailBillList.selectRow === rowId ? "background-color:#d8ffd8;" : "";
        },
        selectRowEvent : (rowId) => {
          dataValue.companyDetailBillList.selectRow = rowId;
        }
      },


      showModel2 : {

        open : ( row )=>{
          dataValue.showModel2.detailCompany = row['companyName'];
          dataValue.showModel2.visible = true;
        },
        goClose :()=>{
          dataValue.showModel2.visible = false;
        }

      },

      getCompanyDetailBillList : {
        http : () => {
          dataValue.companyDetailBillList.loading = true;
          proxy.newApi('/uplater/bom/get.company.detail.bill', dataValue.companyDetailBillList.input).then((res) => {
            if (res.code !== 200) {
              message.error(res.message);
            } else {
              dataValue.companyDetailBillList.output.rowCount = res.data["rowCount"];
              dataValue.companyDetailBillList.output.list = res.data["list"];
              dataValue.companyDetailBillList.loading = false;
            }
          })
        },

        toPage : (pageId) => {
          dataValue.companyDetailBillList.input.page = pageId;
          allEvent.getCompanyDetailBillList.http();
        }
      },

      init : () => {
        dataValue.companyDetailBillList.input.companyName = dataValue.rowObj["companyName"];
        dataValue.showModel2.listCompany                   = dataValue.rowObj["companyName"];
        allEvent.getCompanyDetailBillList.http();
      }

    }

    allEvent.init();

    return {
      dataValue, allEvent
    }

  }
}
</script>

<style scoped>
.divSearch { left: 0; width: 100%; padding: 10px; }
.divSearch .model { width: 200px; margin-left: 10px; }
.divSearch .company { width: 250px; }
.divSearch .buttSearch { margin-left: 10px; }
.divTab { width: 100%; padding: 0; }
.loadingClass { width: 100%; text-align: center; padding-top: 100px; }

.set{ margin-right:5px; }
</style>