<template>
  <div class="divSearch" v-if="dataValue.companyList.loading === false">


    <a-input class="company" placeholder="公司名称" @pressEnter="allEvent.getCompanyList.toPage(1)"
             v-model:value="dataValue.companyList.input.companyName"/>
    <a-input class="model" placeholder="型号名称" @pressEnter="allEvent.getCompanyList.toPage(1)"
             v-model:value="dataValue.companyList.input.modelName"/>
    <a-checkbox class="link" v-model:checked="dataValue.companyList.input.isLink">只看有联系方式</a-checkbox>
    <a-button class="buttSearch" type="primary" @click="allEvent.getCompanyList.toPage(1)">
      <search-outlined/>
    </a-button>

  </div>

  <a-spin class="loadingClass" v-if="dataValue.companyList.loading === true" tip="正在加载数据 ..."></a-spin>
  <div v-if="dataValue.companyList.loading === false" class="divTab">
    <table class="ahuiTable">
      <tr>
        <th>编号</th>
        <th>单号</th>
        <th>公司名</th>
        <th>公司邮件</th>
        <th>公司电话</th>
        <th>联系人邮件</th>
        <th>联系人手机</th>
        <th>型号数量</th>
        <th>更新时间</th>
        <th>操作</th>
        <th>运营</th>
      </tr>

      <tr v-for="aRow in dataValue.companyList.output.list" :style="allEvent.rowColor.selectRowColor(aRow['indexId'])"
          @click="allEvent.rowColor.selectRowEvent(aRow['indexId'])" >
        <td class="min">{{ aRow['indexId'] }}</td>
        <td class="min">{{ aRow['maxUpModelLinkId'] }}</td>
        <td class="hh">{{ aRow['companyName'] }}</td>
        <td class="hh">{{ aRow['companyEmail'] }}</td>
        <td class="hh">{{ aRow['companyTel'] }}</td>
        <td class="hh">{{ aRow['linkmanEmailName'] }}：{{ aRow['linkmanEmail'] }}</td>
        <td class="hh">{{ aRow['linkManMobileName'] }}：{{ aRow['linkmanMobile'] }}</td>
        <td class="min">{{ aRow['modelTypeSize'] }}</td>
        <td class="min">{{ aRow['maxNodeCreateTime'] }}</td>
        <td class="min"><a @click="allEvent.showDetail.goOpen(aRow)">查看</a></td>
        <td class="min"><a @click="allEvent.toMail.goOpen(aRow)">邮件</a></td>
      </tr>
      <tr>
        <td colspan="100" class="pages">
          <APages :number_of_page="dataValue.companyList.input.pageOfNumber"
                  :this_page="dataValue.companyList.input.page" :all_page="dataValue.companyList.output.allPage"
                  @toPage="allEvent.getCompanyList.toPage" :all_count="dataValue.companyList.output.rowCount"
          />
        </td>
      </tr>
    </table>

  </div>

  <a-drawer
      title="查看订单信息"
      width="950"
      :visible="dataValue.showDetail.visible"
      :maskClosable="true"
      :destroyOnClose="true"
      placemant="right"
      @close="allEvent.showDetail.goClose"
  >
    <detailShow :row_obj="dataValue.showDetail.rowObj"/>
  </a-drawer>

  <a-drawer
      title="发送推广邮件"
      width="950"
      :visible="dataValue.toMail.visible"
      :maskClosable="true"
      :destroyOnClose="true"
      placemant="right"
      @close="allEvent.toMail.goClose"
  >
    <toMailShow :row_obj="dataValue.toMail.rowObj" />
  </a-drawer>

</template>

<script>

import {reactive} from "vue";
import proxy from "@/utils/axios";
import {message} from "ant-design-vue";
import APages from "@/tools/pages"
import detailShow from "@/view/uplater/up_company_detail_bill";
import toMailShow from "@/view/uplater/to_mail";

export default {
  name : "up_company_list",
  emits : [ 'setMenuKey' ],
  components : { APages, detailShow, toMailShow },
  setup(props, { emit }) {

    emit('setMenuKey', 'yy.ppgstg');

    let dataValue = reactive({

      showDetail :{
        visible : false,
        rowObj : null
      },

      toMail : {
        visible : false,
        rowObj : null
      },

      companyList : {

        loading : false,
        selectRow : 0,

        input : {
          "page" : 1,
          "pageOfNumber" : 20,
          "companyName" : "",
          "modelName" : "",
          "isLink" : false
        },
        output : {
          "rowCount" : 0,
          "allPage" : 6,
          "list" : []
        }
      }

    })

    let allEvent = {

      rowColor : {
        selectRowColor : (rowId) => {
          return dataValue.companyList.selectRow === rowId ? "background-color:#d8ffd8;" : "";
        },
        selectRowEvent : (rowId) => {
          dataValue.companyList.selectRow = rowId;
        }
      },

      toMail : {
        goOpen :(row)=>{
          dataValue.toMail.visible = true;
          dataValue.toMail.rowObj = row;
        },
        goClose : ()=>{
          dataValue.toMail.visible = false;
        }
      },

      showDetail : {

        goOpen :(row)=>{
          dataValue.showDetail.visible = true;
          dataValue.showDetail.rowObj = row;
        },
        goClose : ()=>{
          dataValue.showDetail.visible = false;
        }

      },


      getCompanyList : {
        http : () => {
          dataValue.companyList.loading = true;
          proxy.newApi('/uplater/bom/get.company.list', dataValue.companyList.input).then((res) => {
            if (res.code !== 200) {
              message.error(res.message);
            } else {
              dataValue.companyList.output.allPage = res.data["allPage"];
              dataValue.companyList.output.rowCount = res.data["rowCount"];
              dataValue.companyList.output.list = res.data["list"];
              dataValue.companyList.loading = false;
            }
          })
        },

        toPage : (pageId) => {
          dataValue.companyList.input.page = pageId;
          allEvent.getCompanyList.http();
        }
      },

      init : () => {
        allEvent.getCompanyList.http();
      }

    }

    allEvent.init();

    return {
      dataValue, allEvent
    }

  }
}
</script>

<style scoped>
.divSearch { left: 0; width: 100%; padding: 10px; }
.divSearch .model { width: 200px; margin-left: 10px; }
.divSearch .company { width: 250px; }
.divSearch .buttSearch { margin-left: 10px; }
.divTab { width: 100%; padding: 0 10px 10px 10px; }
.loadingClass { width: 100%; text-align: center; padding-top: 100px; }
.link{ margin-left: 10px; }
.set{ margin-right:5px; }
</style>