<template>
  <div>
    <div class="lDiv">
      <label>公司名称：</label>
      <label style="margin-left: 10px;">{{dataValue.rowObj['companyName']}}</label>
    </div>
    <div class="lDiv">
      <label>邮件列表：</label>
      <a-select style="width:calc(100% - 70px)" v-model:value="dataValue.sendMail.toers">
        <template v-for="aMail in dataValue.companyMails.output.list">
          <a-select-option :value="aMail['linkMail']">{{ aMail['linkMail'] }} ({{ aMail['linkName'] }} )</a-select-option>
        </template>
      </a-select>
    </div>
    <br />
    <div class="lDiv">
      <div style="margin-bottom: 8px;">发送运营邮件，我的邮件（{{ dataValue.sendMail.fromEmail }}）：</div>
      <a-input v-model:value="dataValue.sendMail.toers" placeholder="收件人" />
      <a-input  style="margin-top: 5px;" v-model:value="dataValue.sendMail.subject" placeholder="主题" />
      <a-textarea style="margin-top: 5px; height: 200px;" placeholder="邮件内容"
                  v-model:value="dataValue.sendMail.body" />
    </div>

    <a-popconfirm title="请确认您的邮件内容？" ok-text="是" cancel-text="否" @confirm="allEvent.sendMail">
      <a-button type="primary">发送邮件</a-button>
    </a-popconfirm>
  </div>
</template>

<script>

import {reactive} from "vue";
import proxy from "@/utils/axios";
import {message} from "ant-design-vue";

export default {
  name : "to_mail",
  components : { },
  props : [ 'row_obj' ],
  setup(props, { emit }) {

    let dataValue = reactive({
      rowObj : props.row_obj,

      companyMails :{
        input : {
          companyName : ""
        },
        output : {
          selectEmail : "",
          rowCount : 0,
          list : []
        }
      },

      sendMail : {
        employeeName : "",
        fromEmail : "",
        fromPasswd : "",
        toers : "",
        ccers : "",
        subject : "",
        body : `
          Dear XX总
          您好，我是闲芯的XX
          今天有了解到到XX型号贵司有用到，请问现在这颗料有库存可以出售吗？或者贵司有哪些品牌价格比较好的，也长期在和原厂或代理在定，我们可以加单的？
          期待您的回复，顺祝商祺！
        `
      }

    })

    let allEvent = {

      sendMail : ()=>{

        alert("暂时关闭");

        // proxy.newApi('/uplater/mail/send', dataValue.sendMail ).then((res) => {
        //   if (res.code !== 200) {
        //     message.error(res.message);
        //   }else{
        //     message.info( "邮件提交成功，请勿重复提交发送！" );
        //   }
        // });

      },

      getMyInfo : ()=>{

        proxy.newApi('/organization/employee/get', {} ).then((res) => {
          if (res.code !== 200) {
            message.error(res.message);
          } else {
            dataValue.sendMail.employeeName = res["data"]["employeeName"];
            dataValue.sendMail.fromEmail    = res["data"]["email"] +"@chipslinking.com";
            dataValue.sendMail.fromPasswd   = res["data"]["emailPass"];
          }
        });

      },

      getCompanyEmail : ()=>{

        proxy.newApi('/uplater/company/get.company.email', dataValue.companyMails.input).then((res) => {
          if (res.code !== 200) {
            message.error(res.message);
          } else {
            dataValue.companyMails.output.rowCount = res.data["rowCount"];
            dataValue.companyMails.output.list = res.data["list"];
            if( dataValue.companyMails.output.list.length > 0 ){
              dataValue.sendMail.toers = res.data["list"][0]['linkMail'];
            }
          }
        })

      },

      init : () => {
        dataValue.companyMails.input.companyName = dataValue.rowObj['companyName'];
        dataValue.sendMail.subject = "您好";
        allEvent.getCompanyEmail();
        allEvent.getMyInfo();
      }

    }

    allEvent.init();

    return {
      dataValue, allEvent
    }

  }
}
</script>

<style scoped>
.lDiv{ clear: both; margin-bottom: 20px; }
</style>